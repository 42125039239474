import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { UsurpFilter } from 'site-modules/shared/components/inventory/usurp-filter/usurp-filter';
import { ToggleButtonGroup } from 'site-modules/shared/components/inventory/toggle-button-group/toggle-button-group';
import { ToggleButton } from 'site-modules/shared/components/inventory/toggle-button/toggle-button';
import { get } from 'lodash';

export function UsurpTabsGroup(props) {
  const { className, selectedTab, onUpdate, facets, facetsConfig, searchResultsFound, isUsed } = props;
  const [activeTab, setActiveTab] = useState(selectedTab);

  const selectedFacet = facets.find(({ type }) => type === activeTab);

  const handleButtonChange = useCallback(
    (_, value) => {
      setActiveTab(value);
      const newSelectedFacet = facets.find(({ type }) => type === value);
      const filterOnSelect = get(newSelectedFacet, 'config.filterOnSelect');

      if (filterOnSelect) {
        onUpdate(filterOnSelect.type, { value: filterOnSelect.value }, true);
      }
    },
    [facets, onUpdate]
  );

  return (
    <div className={className}>
      <ToggleButtonGroup onChange={handleButtonChange} value={activeTab} className="mb-1">
        {facets.map(facet => (
          <ToggleButton key={facet.type} value={facet.type} data-tracking-id={facet.config.dataTrackingId}>
            {facet.config.title}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <UsurpFilter
        facet={selectedFacet}
        withTitle={false}
        onUpdate={onUpdate}
        facetsConfig={facetsConfig}
        searchResultsFound={searchResultsFound}
        isUsed={isUsed}
      />
    </div>
  );
}

UsurpTabsGroup.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  facetsConfig: PropTypes.shape({}).isRequired,
  facets: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  searchResultsFound: PropTypes.number,
  isUsed: PropTypes.bool,
};

UsurpTabsGroup.defaultProps = {
  facets: [],
  className: '',
  searchResultsFound: undefined,
  isUsed: false,
};
