import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'site-modules/shared/components/link/link';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import {
  KNOW_YOUR_BUYING_POWER,
  SEE_PAYMENT_ON_ELIGIBLE_CARS,
  CAPITAL_ONE_ENTRY_POINT_CONTENT,
  DISCOVER_YOUR_REAL_PAYMENT,
  PREQUALIFY_TO_SEE_YOUR_PAYMENTS_ON_ELIGIBLE_CARS,
} from 'client/site-modules/shared/constants/financing/financing-constants';

export const CapitalOneEntryPointSrpFilters = ({ ted6443Recipe }) => {
  const TED6443Chal3 = (
    <div className="d-flex">
      <div className="pr-0_5 d-flex">
        <img src={getStaticImageUrl('/img/car-buying/capital1/bullseye-icon.svg')} alt="" loading="lazy" width={30} />
      </div>
      <div>
        <h4 className="text-green-30 mb-0 size-16">{KNOW_YOUR_BUYING_POWER}</h4>
        <div>{SEE_PAYMENT_ON_ELIGIBLE_CARS}</div>
        <Link
          to="/financing/prequalify-and-shop/basics/"
          className="text-blue-40"
          data-tracking-id="financing_cta"
          target="_blank"
        >
          {CAPITAL_ONE_ENTRY_POINT_CONTENT.TITLE}
          <i className="icon-arrow-right4 mr-0_25 size-10" aria-hidden />
        </Link>
      </div>
    </div>
  );
  const TED6443Chal4 = (
    <Link
      to="/financing/prequalify-and-shop/basics/"
      className=" text-decoration-none"
      data-tracking-id="financing_cta"
      data-tracking-value={CAPITAL_ONE_ENTRY_POINT_CONTENT.TITLE}
      target="_blank"
    >
      <div className="d-flex flex-column">
        <h4 className="text-green-30 mb-0 size-16">{DISCOVER_YOUR_REAL_PAYMENT}</h4>
        <div className="d-flex size-16">
          <div>{PREQUALIFY_TO_SEE_YOUR_PAYMENTS_ON_ELIGIBLE_CARS}</div>

          <i className="icon-arrow-right4 mr-0_25 text-blue-40" aria-hidden />
        </div>
      </div>
    </Link>
  );
  let renderTED6443;
  switch (ted6443Recipe) {
    case 'chal-3':
      renderTED6443 = TED6443Chal3;
      break;
    case 'chal-4':
      renderTED6443 = TED6443Chal4;
      break;
    default:
      renderTED6443 = null;
      break;
  }
  return <div style={{ marginTop: '36px' }}>{renderTED6443}</div>;
};
CapitalOneEntryPointSrpFilters.propTypes = {
  ted6443Recipe: PropTypes.string,
};

CapitalOneEntryPointSrpFilters.defaultProps = {
  ted6443Recipe: '',
};
