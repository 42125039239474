import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { get, isEqual, noop } from 'lodash';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
/* Constants */
import { MAKE, MODEL } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { TrackingConstant } from 'client/tracking/constant';
/* Utils */
import { EventToolbox } from 'client/utils/event-toolbox';
import { buildFilterPixelValue } from 'site-modules/shared/utils/inventory-utils/build-filter-pixel-value';
import { getMakeName, getModelName } from 'site-modules/shared/utils/inventory/multi-make-model';
/* Components */
import { UsurpFilter } from 'site-modules/shared/components/inventory/usurp-filter/usurp-filter';
import { SearchItem } from 'site-modules/shared/components/inventory/usurp-multi-select-group/search-item';

import './usurp-multi-select-group.scss';

const SEARCH_NUMBER = 6;

function fireRemoveFilterTracking({ facets, type, value, name }) {
  if (!value) return;

  const creativeId = get(facets.find(({ type: facetType }) => type === facetType), 'config.creativeId');

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.VIEW_SEARCH_RESULT,
      subaction_name: TrackingConstant.FILTER_SEARCH_RESULT_REMOVE,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      creative_id: creativeId,
      value: buildFilterPixelValue(type, value, name),
    },
  });
}

function fireAddMultiTracking({ searches, facets }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.VIEW_SEARCH_RESULT,
      subaction_name: TrackingConstant.MULTI_SEARCH,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.SELECT_CHANGE,
      creative_id: get(facets[0], 'config.creativeId'),
      value: [
        searches.length,
        ...searches.map(({ make, model }) => {
          const makeName = getMakeName({ facets, make });
          const modelName = getModelName({ facets, makeName, model });

          return `${makeName}${modelName ? ` ${modelName}` : ''}`;
        }),
      ].join(','),
    },
  });
}

function getInitialSearches({ facets, selectedFacets }) {
  const makes = get(selectedFacets, MAKE, []);
  const models = get(selectedFacets, MODEL, []);
  const modelFacets = get(facets.find(({ type }) => type === MODEL), 'groups', {});

  if (makes.length <= 1 && models.length <= 1) return [];

  return makes.reduce((res, make) => {
    const makeName = getMakeName({ facets, make });
    const makeModelValues = get(modelFacets, makeName, []);
    const modelsOfMake = models.filter(model => makeModelValues.find(({ value }) => value === model));
    let newRes = [{ [MAKE]: make, [MODEL]: '' }];

    if (modelsOfMake.length) {
      newRes = modelsOfMake.map(model => ({ [MAKE]: make, [MODEL]: model }));
    }

    return [...res, ...newRes];
  }, []);
}

function getInitialMake(selectedFacets) {
  const makes = get(selectedFacets, MAKE, []);
  const models = get(selectedFacets, MODEL, []);

  return makes.length === 1 && models.length <= 1 ? makes[0] : '';
}

function getInitialModel(selectedFacets) {
  const makes = get(selectedFacets, MAKE, []);
  const models = get(selectedFacets, MODEL, []);

  return makes.length === 1 && models.length === 1 ? models[0] : '';
}

export function UsurpMultiSelectGroup({
  facets,
  selectedFacets,
  onUpdate,
  withInnerDividers,
  isElectricRangeFacetDisplayed,
  setIsMultiMM,
  facetsConfig,
  isUsed,
}) {
  // filter values added to blue bubbles
  const [searches, setSearches] = useState(getInitialSearches({ facets, selectedFacets }));
  // selected make value in select input
  const [currentMake, setCurrentMake] = useState(getInitialMake(selectedFacets));
  // selected model value in select input
  const [currentModel, setCurrentModel] = useState(getInitialModel(selectedFacets));
  const numberOfSavedSearches = searches.length;
  const showAddMakeLink = !!currentMake && numberOfSavedSearches < SEARCH_NUMBER;
  const showAddVehicleLink = !!currentMake && !!currentModel && !numberOfSavedSearches;
  const prevSelectedMakes = useRef(get(selectedFacets, MAKE, []));
  const prevSelectedModels = useRef(get(selectedFacets, MODEL, []));

  useEffect(() => {
    setIsMultiMM(!!searches.length);
  }, [searches, setIsMultiMM]);

  useEffect(
    () => {
      const currSelectedMakes = get(selectedFacets, MAKE, []);
      const currSelectedModels = get(selectedFacets, MODEL, []);

      // need to update searches if some make has been removed through active filters in drawer on mobile
      // or make/model filters have been updated by search click
      if (
        prevSelectedMakes.current &&
        (!isEqual(prevSelectedMakes.current, currSelectedMakes) ||
          !isEqual(prevSelectedModels.current, currSelectedModels))
      ) {
        setSearches(getInitialSearches({ facets, selectedFacets }));

        // if current make has been removed or updated by search click then set make and model to initial values
        if (!currSelectedMakes.includes(currentMake)) {
          setCurrentMake(getInitialMake(selectedFacets));
          setCurrentModel(getInitialModel(selectedFacets));
        } else if (
          currSelectedMakes.length === 1 &&
          currSelectedModels.length === 1 &&
          getInitialModel(selectedFacets) !== currentModel
        ) {
          // special case when only model updates by search click
          setCurrentModel(getInitialModel(selectedFacets));
        }

        prevSelectedMakes.current = currSelectedMakes;
        prevSelectedModels.current = currSelectedModels;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFacets]
  );

  function resetSelectInputs() {
    setCurrentMake('');
    setCurrentModel('');
  }

  const addToSearches = useCallback(() => {
    const searchToAdd = { [MAKE]: currentMake, [MODEL]: currentModel };
    const currentMakeWithModelPair = searches.find(({ make, model }) => make === currentMake && !!model);
    const isSearchAlreadyAdded = !!searches.find(search => isEqual(search, searchToAdd));

    // do not add a search when a model has not been selected and there is already a pair of current make with a model
    if ((!currentModel && currentMakeWithModelPair) || isSearchAlreadyAdded) return;

    // filter out the pair of current make without a model
    const searchesWithoutMake = searches.filter(({ make, model }) => make !== currentMake || !!model);
    // add selected make (and model) to searches
    const updatedSearches = [...searchesWithoutMake, searchToAdd];

    fireAddMultiTracking({ searches: updatedSearches, facets });
    setSearches(updatedSearches);
  }, [searches, currentMake, currentModel, facets]);

  const handleAddAnotherClick = useCallback(() => {
    addToSearches();
    resetSelectInputs();
  }, [addToSearches]);

  useEffect(
    () => {
      if (currentModel && numberOfSavedSearches) {
        // save search right after model change if searches are not empty
        handleAddAnotherClick();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentModel]
  );

  useEffect(
    () => {
      if (currentMake && numberOfSavedSearches) {
        // save search right after make change if searches are not empty
        addToSearches();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentMake]
  );

  function handleFilterUpdate(type, facetObj, isChecked) {
    const currentFilterValue = type === MAKE ? currentMake : currentModel;
    // Rewrite value for when Any has been selected
    const facet = { ...facetObj, value: facetObj.value || currentFilterValue };
    const selectedValue = facet.value || facet.name;
    // searches contains only filter values added to blue bubbles
    const searchesValuesOfType = searches.map(search => search[type]);
    // the flag that determines if previously selected value should be removed
    const removeCurrentValueFromUrl = !!currentFilterValue && !searchesValuesOfType.includes(currentFilterValue);
    // selectedFacets contains all filter values from url
    const isSelectedValueInUrl = get(selectedFacets, type, []).includes(selectedValue);

    // update values in select inputs
    if (type === MAKE) {
      setCurrentMake(isChecked ? selectedValue : '');
      setCurrentModel('');
    } else {
      // a model has been updated
      setCurrentModel(isChecked ? selectedValue : '');
    }

    // update url params
    if (removeCurrentValueFromUrl || !isSelectedValueInUrl) {
      onUpdate(type, type === MAKE ? { ...facet, modelToRemove: currentModel } : facet, isChecked);
    }
  }

  function handleRemoveAnotherClick({ make, makeName, model, modelName }) {
    // all occurrences of the make value in searches
    const makeValuesInSearches = searches.filter(search => search.make === make);

    // Remove the make if there is only one such value in the searches
    if (makeValuesInSearches.length === 1) {
      onUpdate(MAKE, { value: make, modelToRemove: model }, false);
    } else if (model) {
      // Remove only selected model
      onUpdate(MODEL, { value: model }, false);
    }

    setSearches(searches.filter(search => !isEqual(search, { make, model })));

    fireRemoveFilterTracking({ facets, type: MAKE, value: make, name: makeName });
    fireRemoveFilterTracking({ facets, type: MODEL, value: model, name: modelName });
  }

  return (
    <div className="usurp-multi-select-group">
      {!!searches.length && (
        <ul className="added-to-search-list list-unstyled mx-0 mb-0_5">
          {searches.map(({ make, model }) => (
            <SearchItem
              key={`${make} ${model}`}
              facets={facets}
              onRemove={handleRemoveAnotherClick}
              make={make}
              model={model}
            />
          ))}
        </ul>
      )}
      <div className="pos-r">
        {numberOfSavedSearches < SEARCH_NUMBER &&
          facets.map((facet, index) => {
            const { filterClassName = 'mb-1' } = facet.config;
            const isLast = index === facets.length - 1;
            return (
              <Fragment key={facet.type || facet.group}>
                <UsurpFilter
                  facet={facet}
                  onUpdate={handleFilterUpdate}
                  className={!isLast && !withInnerDividers ? filterClassName : ''}
                  selectedFacets={selectedFacets}
                  facetsConfig={facetsConfig}
                  isElectricRangeFacetDisplayed={isElectricRangeFacetDisplayed}
                  currentMMConfig={{
                    currentMakeName: getMakeName({ facets, make: currentMake }),
                    currentMake,
                    currentModel,
                  }}
                  isUsed={isUsed}
                />

                {withInnerDividers && !isLast && <hr className="my-1" />}
              </Fragment>
            );
          })}
        {showAddMakeLink && (
          <Button
            color="link"
            onClick={handleAddAnotherClick}
            className={classnames(
              'add-another-btn text-primary-darker size-16 px-0 pb-0 text-transform-none text-decoration-none',
              {
                'pt-1_5': currentMake,
                'pt-0': !currentMake,
              }
            )}
          >
            + Add another {showAddVehicleLink ? 'vehicle' : 'make'}
          </Button>
        )}
      </div>
    </div>
  );
}

UsurpMultiSelectGroup.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  facetsConfig: PropTypes.shape({}).isRequired,
  facets: PropTypes.arrayOf(PropTypes.shape({})),
  withInnerDividers: PropTypes.bool,
  selectedFacets: PropTypes.shape({}),
  isElectricRangeFacetDisplayed: PropTypes.bool,
  isUsed: PropTypes.bool,
  setIsMultiMM: PropTypes.func,
};

UsurpMultiSelectGroup.defaultProps = {
  facets: [],
  withInnerDividers: false,
  selectedFacets: {},
  isElectricRangeFacetDisplayed: false,
  isUsed: false,
  setIsMultiMM: noop,
};
